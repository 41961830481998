<template>
  <div class="w-full md:w-1/3 2xl:w-1/2 px-1 h-full mb-2">
    <div
      class="bg-bg_alt_color rounded-md h-full p-3 flex flex-col justify-between"
    >
      <MyAccountOrdersCardInformationOrder :bill="billLocal" />
      <div
        class="flex justify-between items-center cursor-pointer"
        @click="isDialougeOpen = true"
      >
        <div class="mb-2 font-bold text-sm text-gray-700">
          {{ t('order_details') }}:
        </div>

        <v-icon icon="fa: fa-solid fa-chevron-down" :size="16" class="mx-1" />
      </div>
    </div>
    <EStoreDialouge v-model="isDialougeOpen" :with-vmodel="true" width="auto">
      <template #content>
        <MyAccountOrdersShow :bill-id="billLocal.id" :with-seo="false" :expansion-bill-details="true" />
      </template>
    </EStoreDialouge>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{ bill: UserOrder }>()
const { billModel } = useMenuModel()
const billLocal = billModel(props.bill)
const { t } = useI18n()
const isDialougeOpen = ref<boolean>(false)
</script>
